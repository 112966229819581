@use "sass:map";
@use "@angular/material" as mat;

// Theme SCSS
@mixin input-theme($theme) {
  $background: map.get($theme, background);

  input {
    color-scheme: light;
    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px map.get($background, card) inset;
      -webkit-box-shadow: 0 0 0px 1000px map.get($background, card) inset;
    }
  }
}

// Custom SCSS
.custom-overlay-panel {
  transform: translateX(-10px) translateY(32px) !important;
}
