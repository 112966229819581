@use 'sass:map';
@use '@angular/material' as mat;

// Theme SCSS
@mixin table-theme($theme) {
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);

  .table-wrapper {
    .table-content {
      background: map.get($background, card);
      border: 1px solid map.get($foreground, divider);

      .mat-table {
        tbody {
          tr {
            &:hover {
              background: map.get($mat-primary, "hover");
            }
          }
        }
      }
    }
  }
}

// Custom SCSS