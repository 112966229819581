@use "sass:map";
@use "@angular/material" as mat;

// Theme SCSS
@mixin processed-po-report-theme($theme) {
    $foreground: map.get($theme, foreground);
    $background: map.get($theme, background);

    app-processed-po-report {
        .table-wrapper {
            header {
                background-color: map.get($background, card);
                border-bottom: 1px solid map.get($foreground, divider);
            }

            .table-content {
                .mat-table {
                    th {
                        &.mat-header-cell {
                            &:first-of-type {
                                border-right: 1px solid map.get($foreground, divider);
                            }
                        }
                    }

                    td {
                        &.mat-cell {
                            &:first-of-type {
                                border-right: 1px solid map.get($foreground, divider);
                            }
                        }

                        &.mat-footer-cell {
                            border-top: 1px solid map.get($foreground, divider);
                        }
                    }
                }
            }
        }
    }
}