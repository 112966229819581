@use "sass:map";
@use "@angular/material" as mat;

// Theme SCSS
@mixin json-visualizer-theme($theme) {
    $foreground: map.get($theme, foreground);
    $primary: map.get($theme, primary);
    $warn: map.get($theme, warn);

    $type-colors: (
        string: #ff6b6b, // --ngx-json-string
        number: #26A69A, // --ngx-json-number
        boolean: map.get($primary, main), // --ngx-json-boolean
        date: #05668d, // --ngx-json-date
        
        array: map.get($foreground, disabled-text), // --ngx-json-array
        object: map.get($foreground, disabled-text), // --ngx-json-object
        function: map.get($foreground, disabled-text), // --ngx-json-function
        
        "null": $light-text, // --ngx-json-null
        undefined: $light-text, // --ngx-json-undefined
    );

    ngx-json-viewer {
        .ngx-json-viewer {
            .segment {
                .segment-main {
                    word-wrap: break-word;

                    .segment-key {
                        color: map.get($foreground, text) !important;
                    }

                    .toggler {
                        font-size: 0.9rem !important;
                    }

                    .toggler,
                    .segment-separator {
                        color: map.get($foreground, secondary-text) !important;
                    }

                    .segment-value {
                        color: map.get($foreground, text) !important;
                    }
                }
            }

            @each $type,
            $color in $type-colors {
                .segment-type-#{$type} > .segment-main > .segment-value {
                    color: #{$color} !important;
                }
            }

            // special cases need highlighted
            .segment-type-null > .segment-main > .segment-value {
                background-color:  map.get($warn, main) !important;
            }

            .segment-type-undefined>.segment-main {
                & > .segment-key {
                    color: map.get($foreground, disabled-text) !important;
                }

                & > .segment-value {
                    background-color: map.get($foreground, disabled-text) !important;
                }
            }
        }
    }
}