@use "sass:map";
@use "@angular/material" as mat;
@import "palette";
@import "https://fonts.googleapis.com/icon?family=Material+Icons+Outlined";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

$xonarFontConfig: (
  display-4: mat.define-typography-level(93px, 93px, 300, "Inter", -0.0161em),
  display-3: mat.define-typography-level(58px, 58px, 300, "Inter", -0.0086em),
  display-2: mat.define-typography-level(47px, 47px, 400, "Inter", 0em),
  display-1: mat.define-typography-level(33px, 39px, 400, "Inter", 0.0076em),
  /* <h1> */
  headline: mat.define-typography-level(18px, 27px, 400, "Inter", 0em),
  /* <h2> */
  title: mat.define-typography-level(16px, 21px, 700, "Inter", 0.0079em),
  /* <h3> */
  subheading-2: mat.define-typography-level(16px, 28px, 400, "Inter", 0.0094em),
  /* <h4> */
  subheading-1: mat.define-typography-level(14px, 24px, 500, "Inter", 0.0071em),
  /* Base body text */
  body-2: mat.define-typography-level(16px, 24px, 400, "Inter", 0.0313em),
  /* Bolder body text */
  body-1: mat.define-typography-level(14px, 20px, 400, "Inter", 0.0179em),
  /* Smaller body and hint text */
  button: mat.define-typography-level(14px, 14px, 500, "Inter", 0.0893em),
  /* Buttons and anchors */
  caption: mat.define-typography-level(12px, 20px, 400, "Inter", 0.0333em),
  input: mat.define-typography-level(14px, 1.2, 400, "Inter", 0.03em),
);

// Typography: Theme SCSS
@mixin typography-theme($theme) {
  $foreground: map.get($theme, foreground);

  .text-high-emphasis,
  .material-icons.high-emphasis {
    color: map.get($foreground, text);
  }

  .text-medium-emphasis,
  .material-icons.medium-emphasis {
    color: map.get($foreground, secondary-text);
  }

  .text-disabled,
  .material-icons.md-disabled {
    color: map.get($foreground, disabled-text);
  }
}

// Text truncate
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
}

.text-ellipsis-3-lines {
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Text weight
.text-medium {
  font-weight: 500 !important;
}

.text-semibold {
  font-weight: 600 !important;
}

.text-uppercase {
  text-transform: uppercase;
}

// Text Wrap
.pre-wrap {
  white-space: pre-wrap;
}

.break-word {
  word-wrap: break-word;
}

// Text Alignment
.text-center {
  text-align: center;
}

// Text Color: Theme SCSS
@mixin text-color-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);

  .text-primary {
    color: map.get($primary, main);
  }

  .text-accent {
    color: map.get($accent, main);
  }

  .text-warn {
    color: map.get($warn, main);
  }

  .text-link {
    color: map.get($primary, main);
  }
}

.mat-display-1,
.mat-typography .mat-display-1 {
  margin: 0 0 16px;
}

// Google Material Icons Classes
$icon-size: 24px !default;
$sizes: (
  18: 18px,
  24: $icon-size,
  36: 36px,
  48: 48px,
  ) !default;

.material-icons {
  font-family: "Material Icons Outlined",
    "Material Icons";
  font-size: $icon-size;
  /* Preferred icon size */
  line-height: 1;
  display: inline-block;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";

  // Mat Icon Classes(size)
  @each $key,
  $value in $sizes {
    &.md-#{$key} {
      font-size: #{$value};
      width: #{$value};
      height: #{$value};
      line-height: #{$value};

      .mat-icon-button & {
        &.mat-icon {
          line-height: #{$value};
        }
      }
    }
  }

  .mat-badge-content {
    font-family: "Inter";
  }
}

// Mat Icon Button Classes(size)
.mat-icon-button {

  @each $key,
  $value in $sizes {
    &.md-#{$key} {
      width: #{$value + 6};
      height: #{$value + 6};
      line-height: #{$value + 6};

      .material-icons {
        font-size: #{$value};
        width: #{$value};
        height: #{$value};
        line-height: #{$value};

        .mat-icon-button & {
          &.mat-icon {
            line-height: #{$value};
          }
        }
      }
    }
  }
}
