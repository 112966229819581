@use "sass:map";
@use "@angular/material" as mat;

// Default variables
$mat-card-header-size: 40px !default;

// Theme SCSS
@mixin card-theme($theme) {
  $foreground: map.get($theme, foreground);

  .mat-card {
    border: 1px solid map.get($foreground, divider);
  }
}

// Custom SCSS
.mat-card-avatar {
  height: $mat-card-header-size;
  width: $mat-card-header-size;
  border-radius: 50%;
  flex-shrink: 0;

  // Makes `<img>` tags behave like `background-size: cover`. Not supported
  // in IE, but we're using it as a progressive enhancement.
  object-fit: cover;
}
