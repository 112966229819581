@use "sass:map";
@use "@angular/material" as mat;

// Theme SCSS
@mixin banner-theme($theme) {
  $background: map.get($theme, background);

  .mat-banner {
    background-color: map.get($background, card);
  }
}

// Custom SCSS
.mat-banner {
  padding: 16px;
}
