@use "sass:map";
@use "@angular/material" as mat;

// Theme SCSS
@mixin po-entity-history-report($theme) {
    $foreground: map.get($theme, foreground);
    $background: map.get($theme, background);

    app-po-entity-history-report {
        .table-wrapper {
            header {
                background-color: map.get($background, card);
                border-bottom: 1px solid map.get($foreground, divider);
            }

            .table-content {
                .mat-table {
                    td {
                        &.mat-footer-cell {
                            border-top: 1px solid map.get($foreground, divider);
                        }
                    }
                }
            }
        }
    }

}