/* Add global styles to this file, and also import other style files */
// @import './scss/variables';
@use "@angular/material" as mat;
@import "scss/palette";
@import "scss/typography";
@import "scss/grid";
@import "scss/spacing";
@import "scss/containers";
@import "scss/toolbar";
@import "scss/table";
@import "scss/input";
@import "scss/card";
@import "scss/button";
@import "scss/banner";

// Import Page theme SCSS
@import "src/app/apps/reporting/issues-report/issues-report.theme";
@import "src/app/apps/reporting/entity-status/entity-status.theme";
@import "src/app/apps/reporting/lsp-edi-error-report/lsp-edi-error-report.theme";
@import "src/app/apps/reporting/po-entity-history-report/po-entity-history-report.theme";
@import "src/app/apps/reporting/processed-po-report/processed-po-report.theme";
@import "src/app/shared/components/json-visualizer/json-visualizer.theme";

// Define a mixin for easier access
@mixin components-theme($theme) {
  // Components theme
  @include card-theme($theme);
  @include table-theme($theme);
  @include banner-theme($theme);
  @include input-theme($theme);
  @include typography-theme($theme);
  @include text-color-theme($theme);
  @include json-visualizer-theme($theme);

  // Pages theme
  @include issues-report-theme($theme);
  @include entity-status-theme($theme);
  @include lsp-edi-error-report-theme($theme);
  @include po-entity-history-report($theme);
  @include processed-po-report-theme($theme);
}

// Include the common styles for Angular Material. We include this here so that only
// have to load a single css file for Angular Material in app.

// Fonts: @import "typography";

// Color Palette: @import "palette";

// Compute font config
@include mat.core($xonarFontConfig);

// Theme Config

body {
  --primary-color: #2962ff;
  --primary-lighter-color: #bfd0ff;
  --primary-darker-color: #1946ff;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

body {
  --accent-color: #393939;
  --accent-lighter-color: #c4c4c4;
  --accent-darker-color: #242424;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

// Theme Init: Light theme(Default) for Material component
@include mat.all-component-themes($light-theme);

// Apply the theme to the user components
@include components-theme($light-theme);

// Theme Init: Dark theme for Material component
[data-theme="dark"],
.dark-theme {
  @include mat.all-component-themes($dark-theme);

  // Apply the theme to the user components
  @include components-theme($dark-theme);

  // Input-theme
  input {
    color-scheme: dark;
  }
}

// Specific component overrides, pieces that are not in line with the general theming
// Handle buttons appropriately, with respect to line-height
.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

// Common SCSS
*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

a,
a:link,
a:focus,
a:active {
  text-decoration: none;
}

// Image classes
.img-fluid {
  max-width: 100%;
  // height: auto;
}